.loader {
  width: 96px;
  height: 16px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.25) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0.25) 75%,
    transparent 75%,
    transparent
  );
  font-size: 30px;
  background-size: 1em 1em;
  box-sizing: border-box;
  animation: barStripe 1s linear infinite;
}

@keyframes barStripe {
  0% {
    background-position: 1em 0;
  }
  100% {
    background-position: 0 0;
  }
}
